@font-face {
  font-family: "Brown-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Brown-Regular.eot");
  src: url("/fonts/Brown-Regular.eot?#iefix") format("embedded-opentype"), local("Brown-Regular"),
    url("/fonts/Brown-Regular.woff2") format("woff2"),
    url("/fonts/Brown-Regular.woff") format("woff"),
    url("/fonts/Brown-Regular.ttf") format("truetype"),
    url("/fonts/Brown-Regular.otf") format("opentype");
}

body {
  padding: 0;
  margin: 0;
  font-family: "Brown-Regular", sans-serif;
  font-size: 14px;
  background: #000;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  margin: 0;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}
h2 {
  font-size: 1.6rem;
  font-weight: 300;
  margin: 0;
}

h3 {
  margin: 0;
}

i {
  margin: 0;
  font-style: normal;
}

p {
  margin: 0;
}

#baloon {
  width: 60vw;
  height: 60vw;
  background-color: #3f380a;
  position: fixed;
  bottom: -30vw;
  right: -30vw;
  border-radius: 100%;
  z-index: 0;
}

#baloonLight {
  width: 60vw;
  height: 60vw;
  background-color: #ffdd00;
  position: fixed;
  bottom: -30vw;
  right: -30vw;
  border-radius: 100%;
  z-index: 0;
}

@media screen and (min-width: 520px) {
  #pride {
    height: 100vh;
    width: 15%;
    position: fixed;
    z-index: 0;
    background-image: url("/images/pride.png");
    background-size: cover;
    top: 0;
    right: 0;
  }
}

@media screen and (min-width: 1100px) {
  #ribbonLeft {
    width: 200px;
    height: 600px;
    position: fixed;
    z-index: 0;
    background-image: url("/images/ribbonLeft.png");
    background-size: cover;
    transform: rotate(-10deg);
    bottom: 0;
    left: 2%;
  }

  #ribbonRight {
    width: 200px;
    height: 400px;
    position: fixed;
    z-index: 0;
    background-image: url("/images/ribbonRight.png");
    background-size: cover;
    transform: rotate(10deg);
    bottom: 0;
    right: 1%;
  }

  #norwayFlag {
    width: 360px;
    height: 260px;
    position: fixed;
    z-index: 0;
    background-image: url("/images/norwayFlag.png");
    background-size: cover;
    transform: rotate(25deg);
    top: 20vh;
    left: -25px;
  }

  #iceCream {
    width: 180px;
    height: 250px;
    position: fixed;
    z-index: 0;
    background-image: url("/images/icecream.png");
    background-size: cover;
    transform: rotate(20deg);
    top: 30%;
    right: 200px;
  }

  #hotdog {
    width: 240px;
    height: 170px;
    position: fixed;
    z-index: 0;
    background-image: url("/images/hotdog.png");
    background-size: cover;
    transform: rotate(-15deg);
    top: 50%;
    right: 100px;
  }

  #announcementBubble {
    width: 20vw;
    height: 20vw;
    z-index: 0;
    position: fixed;
    background-color: #6d6110;
    bottom: 7vw;
    right: 7vw;
    border-radius: 100%;
  }

  #vaerbitt {
    width: 200px;
    height: 400px;
    position: fixed;
    z-index: 0;
    background-image: url("/images/vaerbitt.png");
    background-size: cover;
    transform: rotate(10deg);
    top: 50%;
    right: 200px;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
}

@media (min-width: 900px) {
  html,
  body {
    font-size: 18px;
  }

  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2rem;
  }
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.spin {
  animation: fa-spin 2s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
